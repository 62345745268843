import React from "react";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import {Section, Text, Button, MainTitle} from "../components/Core";
import TeamMembers from "./../sections/team-members/TeamMembers"
import { Container, Row, Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../utils/helperFn";

const Team = () => {

    const data = useStaticQuery(graphql`
        query getAllStrapiTeamMembers {
            allStrapiTeamMembers(sort: {fields: order}) {
                nodes {
                    id
                    name
                    position
                    linkedin
                    order
                    details
                    photo {
                        childImageSharp {
                            original {
                                src
                            }
                        }
                    }
                }
            }
            strapiPageHeadings(slug: {eq: "team"}) {
                h1
            }
        }
    `);

    const teamMembers = getProperty(data, 'allStrapiTeamMembers')['nodes'];
    const headingsData = getProperty(data, 'strapiPageHeadings');

    return (
        <>
            <Seo page="team"/>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5" />
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="8">
                                <MainTitle variant="hero">{headingsData.h1}</MainTitle>
                                <Text
                                    as="h4"
                                    color="heading"
                                    fontSize="1.7em"
                                    fontWeight={300}
                                    letterSpacing={-0.75}
                                    mb={0}
                                    className="m-5"
                                >
                                    The driving force behind the fastest growing full fibre network in the UK.
                                </Text>
                            </Col>
                        </Row>
                        <div className="pt-5" />
                        <TeamMembers teamMembers={teamMembers} />
                    </Container>
                </Section>
            </PageWrapper>
        </>
    )

}

export default Team;
